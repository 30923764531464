import * as React from "react";
import * as Styles from "./contacts.module.sass";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "react-responsive-modal/styles.css";
import Seo from "../components/seo";

const ContactPage = () => {
  const dfltStatus = { status: `notsubmitted`, msg: `` };
  return (
    <>
      <Seo
        title={`Контакты`}
        description={`Свяжитесь с нами посредством звонка или оставьте свой номер и мы перезвоним вам.`}
        image={{
          src: `/images/social_preview_contacts.png`,
          width: 1200,
          height: 628,
        }}
      />
      <div className={`${Styles.mainlayer}`}>
        <p>Позвоните нам</p>
        <a href={`tel:+79038976575`}>+79038976575</a>
        <Formik
          initialStatus={dfltStatus}
          initialValues={{ phone: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.phone) {
              errors.phone = "Поле обязательно для заполнения";
            } else if (
              !/^[\d]{10,15}$/i.test(values.phone.replace(/[^\d]/g, ""))
            ) {
              errors.phone =
                "Неизвестный формат номера, необходимо как минимум 10 цифр";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting, setStatus }) => {
            setStatus(dfltStatus);
            let formData = new FormData();
            formData.append(`phone`, values.phone.replace(/[^\d]/g, ""));
            fetch("/form.php", {
              method: "POST",
              body: formData,
            })
              .then((response) => {
                if (response.ok) {
                  return response.json();
                }
                return Promise.reject({
                  status: `error`,
                  msg: `Упс! Что-то пошло не так. Попробуйте обновить страницу и попробовать ещё раз. Или воспользуйтесь кнопкой выше и позвоните нам!`,
                });
              })
              .then((response) => {
                setSubmitting(false);
                setStatus(response);
              })
              .catch((obj) => {
                setSubmitting(false);
                setStatus(obj);
              });
          }}
        >
          {({ isSubmitting, status }) =>
            status && status.status === `ok` ? (
              <div className={``}>{status.msg}</div>
            ) : (
              <>
                <h2>Или оставьте номер и мы перезвоним.</h2>
                <Form>
                  <div>
                    <Field
                      placeholder={`+7 999 999 9999`}
                      className={`${Styles.field}`}
                      type="phone"
                      name="phone"
                    />
                    <ErrorMessage name="phone" component="div" />
                  </div>
                  <div>
                    <button
                      className={`button is-outlined is-radiusless ${
                        Styles.sendbutton
                      } ${isSubmitting ? `is-loading` : ``}`}
                      type="submit"
                    >
                      Отправить
                    </button>
                  </div>
                  {status.status === `error` ? (
                    <div className={``}>{status.msg}</div>
                  ) : (
                    <></>
                  )}
                  <div className={`${Styles.policylink}`}>
                    Нажимая кнопку отправить вы соглашаетесь с{" "}
                    <a
                      href={`/privacy-policy`}
                      target={`_blank`}
                      className={`${Styles.customlink}`}
                    >
                      политикой конфиденциальности
                    </a>
                    .
                  </div>
                </Form>
              </>
            )
          }
        </Formik>
      </div>
    </>
  );
};

export default ContactPage;
